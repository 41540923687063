import loadingSmall from "../icons/loadingSmall.svg";
import plus from "../icons/plus.svg";
import bin from "../icons/binBlack.svg";
import pencil from "../icons/pencil.svg";
import SpinnerSmall from "../icons/SpinnerSmall.svg";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/userData";
import { AppDataContext } from "../providers/appData";
import { FormContext } from "../providers/formValues";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../Layout";

function Beneficiaries() {
  const { user } = useContext(UserContext);
  const [loading, setloading] = useState(false);
  const [delRoling, setdelRoling] = useState({ deleting: false, id: 0 });
  const [focused, setFocused] = useState(false);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [editForm, setEditForm] = useState(false);
  const [loadingBenef, setLoadingBenef] = useState(false);
  document.title = "Beneficiaries-" + appData.business.name;
  const [OpenForm, setOpenForm] = useState(false);
  const [currentBeneficiary, setCurrentBeneficiary] = useState({});
  const history = useHistory();

  const retrieveBeneficiary = () => {
    setLoadingBenef(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(localStorage.getItem("apiURL") + "beneficiary", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setLoadingBenef(false);

        formDispatch({
          type: "INPUTVALUES",
          data: { name: "benefeciary", value: data.data },
        });
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setLoadingBenef(false);
      });
  };

  const deleteBeneficiary = (id, name, number, network, beneficiary_type) => {
    setdelRoling({ deleting: true, id });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();

    urlencoded.append("user_id", String(user.data.user_id));
    urlencoded.append("name", String(name));
    urlencoded.append("number", String(number));
    urlencoded.append("provider", String(network));
    urlencoded.append("beneficiary_type", String(beneficiary_type));

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: urlencoded,
    };

    fetch(localStorage.getItem("apiURL") + "beneficiary/" + id, requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setdelRoling(false);
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "loading",
            value: {
              isOpen: false,
            },
          },
        });

        if (data.status === "success") {
          retrieveBeneficiary();
          toast(data.message);
          setdelRoling(false);
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "loading",
              value: {
                isOpen: false,
              },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });

          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          setdelRoling(false);
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setdelRoling(false);
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "loading",
            value: {
              isOpen: false,
            },
          },
        });
      });
  };
  const editBeneficiaries = (e) => {
    e.preventDefault();
    setloading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();

    urlencoded.append("user_id", String(user.data.user_id));
    urlencoded.append("name", String(currentBeneficiary.name));
    urlencoded.append("number", String(currentBeneficiary.number));
    urlencoded.append("provider", String(currentBeneficiary.provider));
    urlencoded.append(
      "beneficiary_type",
      String(currentBeneficiary.beneficiary_type)
    );

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: urlencoded,
    };

    fetch(
      localStorage.getItem("apiURL") + "beneficiary/" + currentBeneficiary.id,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setloading(false);

        if (data.status === "success") {
          setEditForm(!editForm);
          retrieveBeneficiary();
          toast(data.message);
          setloading(false);
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });

          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          setloading(false);
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setloading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setloading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();

    urlencoded.append("user_id", String(user.id));
    urlencoded.append("name", String(formData.beneficiary_name));
    urlencoded.append("number", String(formData.value_reciever_number));
    urlencoded.append("provider", String(formData.network));
    urlencoded.append("beneficiary_type", String(formData.providerType));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };

    fetch(localStorage.getItem("apiURL") + "beneficiary", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setloading(false);

        if (data.status === "success") {
          setOpenForm(!OpenForm);
          // formDispatch({
          //   type: "INPUTVALUES",
          //   data: {
          //     name: "Alert",
          //     value: {
          //       isOpen: true,
          //       message: data.message,
          //       location: "beneficiaries",
          //     },
          //   },
          // });
          setloading(false);
          retrieveBeneficiary();
          toast(data.message);
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });

          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          setloading(false);
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setloading(false);
      });
  };

  const TransactionType = ["PhoneNum", "Cable", "Electric"];
  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleFocus = (e) => {
    setFocused(true);
  };

  const elecrticityProvider = [
    "IKEDC",
    "EKEDC",
    "KEDCO",
    "PHED",
    "JED",
    "IBEDC",
    "KAEDCO",
    "AEDC",
    "EEDC",
    "BEDC",
  ];
  const airtimeData = ["MTN", "AIRTEL", "GLO", "ETISALAT"];
  const cable = ["DSTV", "GOTV", "STARTIMES"];

  useEffect(() => {
    retrieveBeneficiary();
    formDispatch({
      type: "CLEAR_FORM_DATA",
    });
    window.scrollTo(0, 0);
  }, []);
  function handleEditInputChange(e) {
    setCurrentBeneficiary({
      ...currentBeneficiary,
      name: e.target.value,
    });
  }
  function handleEditnumberChange(e) {
    setCurrentBeneficiary({
      ...currentBeneficiary,
      number: e.target.value,
    });
  }
  function handleEditNetworkChange(e) {
    setCurrentBeneficiary({
      ...currentBeneficiary,
      provider: e.target.value,
    });
  }
  function handleEditProviderChange(e) {
    setCurrentBeneficiary({
      ...currentBeneficiary,
      beneficiary_type: e.target.value,
    });
  }
  function handleEditClick(benef) {
    setCurrentBeneficiary({ ...benef });
  }

  return (
    <Layout PageName={"Beneficiaries"}>
      <div className="relative">
        <div className="text-center ">
          <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 dark:text-gray-100 mb-4">
            Beneficiaries
          </h1>
        </div>
        <div className="relative">
          {loadingBenef ? (
            <div className="flex items-center justify-center h-[70vh]">
              <img src={SpinnerSmall} alt="loading ..." className="" />
            </div>
          ) : (
            <section className="text-gray-700 h-[70vh] overflow-y-scroll">
              <div className="">
                <div className="container  mx-auto">
                  <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 ">
                    <div className="w-full  px-4 py-2">
                      {formData.benefeciary && (
                        <>
                          {formData.benefeciary.map((benef, index) => (
                            <p className="pb-2 bg-primary-black    text-white ">
                              <details>
                                <summary
                                  style={{
                                    marker: "none",
                                    webkitDetailsMarker: { display: "none" },
                                    listStyle: "none",
                                  }}
                                  className="  px-4 flex justify-between items-center"
                                >
                                  <p className="pt-0 mt-0  inline-flex items-center  ">
                                    <div class="mr-2 bg-white dark:bg-gray-900 text-primary-orange  inline-flex items-center justify-center w-7 h-7 text-xs font-medium   rounded-full  ">
                                      {benef.name.toUpperCase().charAt(0)}
                                      {benef.name.toUpperCase().charAt(1)}
                                    </div>
                                    <div>
                                      <p className="pt-0 mt-0 font-semibold ">
                                        {benef.name}
                                      </p>
                                      <p
                                        className="pt-0 mt-0  "
                                        style={{ fontSize: 10 }}
                                      >
                                        {benef.provider} - {benef.number}
                                      </p>
                                    </div>
                                  </p>
                                  <div className="flex justify-between space-x-2">
                                    <button
                                      className="p-2"
                                      onClick={() => {
                                        setEditForm(!editForm);
                                        handleEditClick(benef);
                                      }}
                                    >
                                      <img
                                        src={pencil}
                                        alt="Edit"
                                        className="h-4"
                                      />
                                    </button>
                                    <button
                                      type="p-2"
                                      disabled={delRoling.deleting}
                                      onClick={() =>
                                        deleteBeneficiary(
                                          benef.id,
                                          benef.name,
                                          benef.number,
                                          benef.provider,
                                          benef.beneficiary_type
                                        )
                                      }
                                    >
                                      {delRoling && delRoling.id == benef.id ? (
                                        <div className="flex items-center justify-center">
                                          <img
                                            src={loadingSmall}
                                            alt="loading ..."
                                            className="w-7 h-7 "
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          src={bin}
                                          alt="Delete"
                                          className="h-4"
                                        />
                                      )}
                                    </button>
                                  </div>
                                </summary>

                                <div className="ml-4 mr-4">
                                  {(benef.provider === "MTN" ||
                                    benef.provider === "AIRTEL" ||
                                    benef.provider === "GLO" ||
                                    benef.provider === "ETISALAT") && (
                                    <div className="flex gap-4 my-4">
                                      <button
                                        onClick={() => {
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "fromBeneficiaries",
                                              value: true,
                                            },
                                          });
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "value_reciever_number",
                                              value: benef.number,
                                            },
                                          });
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "network",
                                              value: benef.provider,
                                            },
                                          });
                                          history.push("/data");
                                        }}
                                        type="button"
                                        className="py-2 px-4  bg-primary-orange  focus:ring-primary-black text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                      >
                                        Buy Data
                                      </button>
                                      <button
                                        onClick={() => {
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "fromBeneficiaries",
                                              value: true,
                                            },
                                          });
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "value_reciever_number",
                                              value: benef.number,
                                            },
                                          });
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "network",
                                              value: benef.provider,
                                            },
                                          });
                                          history.push("/airtime");
                                        }}
                                        type="button"
                                        className="py-2 px-4  bg-primary-orange  focus:ring-primary-black text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                      >
                                        Buy Airtime
                                      </button>
                                    </div>
                                  )}
                                  {(benef.provider === "DSTV" ||
                                    benef.provider === "GOTV" ||
                                    benef.provider === "STARTIMES") && (
                                    <div className="flex item-center justify-center my-4">
                                      <button
                                        onClick={() => {
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "fromBeneficiaries",
                                              value: true,
                                            },
                                          });
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "value_reciever_number",
                                              value: benef.number,
                                            },
                                          });
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "network",
                                              value: benef.provider,
                                            },
                                          });
                                          history.push("/cable");
                                        }}
                                        type="button"
                                        className="py-2 px-4  bg-primary-orange  focus:ring-primary-black text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                      >
                                        Buy Cable Subscriptions
                                      </button>
                                    </div>
                                  )}

                                  {(benef.provider === "IKEDC" ||
                                    benef.provider === "EKEDC" ||
                                    benef.provider === "KEDCO" ||
                                    benef.provider === "KEDCO" ||
                                    benef.provider === "PHED" ||
                                    benef.provider === "JED" ||
                                    benef.provider === "IBEDC" ||
                                    benef.provider === "KAEDCO" ||
                                    benef.provider === "AEDC" ||
                                    benef.provider === "EEDC" ||
                                    benef.provider === "BEDC") && (
                                    <div className="flex item-center justify-center my-4">
                                      <button
                                        onClick={() => {
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "fromBeneficiaries",
                                              value: true,
                                            },
                                          });
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "value_reciever_number",
                                              value: benef.number,
                                            },
                                          });
                                          formDispatch({
                                            type: "INPUTVALUES",
                                            data: {
                                              name: "network",
                                              value: benef.provider,
                                            },
                                          });
                                          history.push("/electricity");
                                        }}
                                        type="button"
                                        className="py-2 px-4  bg-primary-orange  focus:ring-primary-black text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                      >
                                        Buy Electricity Subscriptions
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </details>
                              {index < formData.benefeciary.length - 1 && (
                                <hr
                                  className="ml-4 mr-4 bg-gray-300"
                                  style={{ height: "1px", border: "none" }}
                                />
                              )}
                            </p>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {editForm && (
            <div className="container flex items-center justify-center flex-1 h-full mx-auto absolute inset-0 bg-black bg-opacity-10 ">
              <div className="w-full max-w-lg">
                <div className="leading-loose">
                  <form
                    className="max-w-sm p-10 m-auto bg-primary-black bg-opacity-30 rounded shadow-xl relative"
                    onSubmit={editBeneficiaries}
                  >
                    <button
                      type="button"
                      className="absolute right-4 top-4 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      onClick={() => {
                        setEditForm(false);
                      }}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <p className="mb-8 text-2xl font-light text-center text-white">
                      Edit Beneficiary
                    </p>
                    <div className="mb-2">
                      <div className=" relative ">
                        <input
                          type="text"
                          required
                          className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          placeholder="Name"
                          name="beneficiary_name"
                          value={currentBeneficiary.name}
                          onChange={(e) => {
                            handleEditInputChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className=" relative ">
                        <input
                          type="text"
                          className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          placeholder="Beneficiary number"
                          name="value_reciever_number"
                          autoComplete="off"
                          value={currentBeneficiary.number}
                          inputMode="numeric"
                          focused={focused.toString()}
                          required
                          onBlur={handleFocus}
                          onChange={(e) => {
                            handleEditnumberChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <select
                      value={currentBeneficiary.beneficiary_type}
                      name="providerType"
                      onChange={(e) => {
                        handleEditProviderChange(e);
                      }}
                      required
                      className="form-select  px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out  mb-2
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Default select example"
                    >
                      <option value="">Beneficiary Type</option>
                      {TransactionType.map((transaction, index) => {
                        return (
                          <option key={index} value={transaction}>
                            {transaction}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      value={currentBeneficiary.provider}
                      name="network"
                      onChange={(e) => {
                        handleEditNetworkChange(e);
                      }}
                      required
                      className="form-select  px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out  m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Default select example"
                    >
                      <option value="">Service Provider</option>
                      {currentBeneficiary.beneficiary_type === "Electric"
                        ? elecrticityProvider.map((transaction, index) => {
                            return (
                              <option value={transaction}>{transaction}</option>
                            );
                          })
                        : ""}
                      {currentBeneficiary.beneficiary_type === "PhoneNum"
                        ? airtimeData.map((transaction, index) => {
                            return (
                              <option value={transaction}>{transaction}</option>
                            );
                          })
                        : ""}
                      {currentBeneficiary.beneficiary_type === "Cable"
                        ? cable.map((transaction, index) => {
                            return (
                              <option value={transaction}>{transaction}</option>
                            );
                          })
                        : ""}
                    </select>
                    <div className="flex items-center justify-between mt-4">
                      <button
                        type="submit"
                        className="py-2 px-4  bg-primary-orange hover:bg-primary-black focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      >
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <img
                              src={loadingSmall}
                              alt="loading ..."
                              className="w-7 h-7 "
                            />
                          </div>
                        ) : (
                          `Edit`
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {OpenForm && (
            <div className="container flex items-center justify-center flex-1 h-full mx-auto absolute inset-0 bg-black bg-opacity-10 ">
              <div className="w-full max-w-lg">
                <div className="leading-loose">
                  <form
                    className="max-w-sm p-10 m-auto bg-primary-black bg-opacity-30 rounded shadow-xl relative"
                    onSubmit={handleSubmit}
                  >
                    <button
                      type="button"
                      className="absolute right-4 top-4 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      onClick={() => {
                        setOpenForm(!OpenForm);
                      }}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <p className="mb-8 text-2xl font-light text-center text-white">
                      Add beneficiaries
                    </p>
                    <div className="mb-2">
                      <div className=" relative ">
                        <input
                          type="text"
                          required
                          className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          placeholder="Name"
                          name="beneficiary_name"
                          value={formData.beneficiary_name}
                          onChange={(e) => {
                            formOnChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className=" relative ">
                        <input
                          type="text"
                          className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                          placeholder="Beneficiary number"
                          name="value_reciever_number"
                          autoComplete="off"
                          value={formData.value_reciever_number}
                          inputMode="numeric"
                          focused={focused.toString()}
                          required
                          onBlur={handleFocus}
                          onChange={(e) => {
                            formOnChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <select
                      value={formData.providerType}
                      name="providerType"
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      required
                      className="form-select  px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out  mb-2
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Default select example"
                    >
                      <option value="">Beneficiary Type</option>
                      {TransactionType.map((transaction, index) => {
                        return (
                          <option value={transaction}>{transaction}</option>
                        );
                      })}
                    </select>
                    <select
                      value={formData.network}
                      name="network"
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      required
                      className="form-select  px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out  m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      aria-label="Default select example"
                    >
                      <option value="">Service Provider</option>
                      {formData.providerType === "Electric"
                        ? elecrticityProvider.map((transaction, index) => {
                            return (
                              <option value={transaction}>{transaction}</option>
                            );
                          })
                        : ""}{" "}
                      {formData.providerType === "PhoneNum"
                        ? airtimeData.map((transaction, index) => {
                            return (
                              <option value={transaction}>{transaction}</option>
                            );
                          })
                        : ""}
                      {formData.providerType === "Cable"
                        ? cable.map((transaction, index) => {
                            return (
                              <option value={transaction}>{transaction}</option>
                            );
                          })
                        : ""}
                    </select>
                    <div className="flex items-center justify-between mt-4">
                      <button
                        type="submit"
                        className="py-2 px-4  bg-primary-orange hover:bg-primary-black focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      >
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <img
                              src={loadingSmall}
                              alt="loading ..."
                              className="w-7 h-7 "
                            />
                          </div>
                        ) : (
                          `Add`
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {!OpenForm && (
            <div className="absolute right-5 md:right-20 bottom-0 ">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-orange  opacity-75 "></span>
              <button
                type="button"
                className="w-10 h-10 md:w-20 md:h-20 text-base font-medium rounded-full bg-primary-orange    "
                onClick={() => {
                  setOpenForm(!OpenForm);
                  setEditForm(false);
                }}
              >
                <img
                  src={plus}
                  alt="Add"
                  className="relative h-4 w-4 md:h-8 md:w-8  m-auto "
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Beneficiaries;
