import React, { useContext, useEffect, useState } from "react";
import { getOS } from "../helper/getOs";
import { useHistory } from "react-router-dom";
import { FormContext } from "../providers/formValues";
import { AppDataContext } from "../providers/appData";
import { UserContext } from "../providers/userData";
import Layout from "../Layout";
import PaymentType from "../components/paymentType";
import Api from "../helper/Api";
import loadingSmall from "../icons/loadingSmall.svg";

function VerifyPhone() {
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [focused, setFocused] = useState(false);
  const [sending, setSending] = useState(false);
  const [verifyingMeter, setVerifyingMeter] = useState(false);

  document.title = "Verify Phone Number-" + appData.business.name;

  const handleFocus = (e) => {
    setFocused(true);
  };
  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
    document.getElementById("otp").addEventListener("input", function () {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "otp",
          value: this.value.replace(/\D/g, ""),
        },
      });
    });

    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  const onValidSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    Api(
      "post",
      "verify_phone",
      {
        otp: formData.otp,
      },
      onSuccess,
      onFail,
      user.token,
      history
    );
  };

  function onSuccess(data) {
    setSending(false);

    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: { isOpen: true, message: data.message, show: true },
      },
    });
  }

  function onFail(error) {
    setSending(false);
    setVerifyingMeter(false);
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "ref", value: Math.random().toString(36).slice(2) },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: {
          isOpen: true,
          message: error,
          type: "error",
        },
      },
    });
  }
  const getOTP = (e) => {
    e.preventDefault();
    setVerifyingMeter(true);
    Api("get", "otp", {}, onOTPSuccess, onFail, user.token, history);
  };

  function onOTPSuccess(data) {
    setVerifyingMeter(false);
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "otpRetrieved",
        value: true,
      },
    });
  }
  return (
    <Layout PageName={"Phone Verification"}>
      <div className="">
        <h2 className="font-medium text-sm mt-4 md:text-lg font-bold">
          Verify Your Phone Number{" "}
          <span class="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
              />
              <path
                fill="#fff"
                d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
              />
            </svg>
          </span>
        </h2>
        <p className="font-medium text-sm md:text-xl   mt-2">
          We need to verify your phone number. This will help us keep your
          account safe and secure. Wrong number? You can change your phone
          number on the profile menu. You need to verify your phone number
          before 1/3/2024
        </p>

        <div className="grid grid-cols-4 gap-6 "></div>
      </div>
      <form onSubmit={onValidSubmit}>
        <div className="mt-3">
          <div className="w-full space-y-4">
            <div className="w-full">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-0">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Phone Number
                      </div>
                    </div>

                    <div className="w-full">
                      <div className=" relative ">
                        <label>
                          <div>
                            <input
                              type="text"
                              id="phone_number"
                              className=" rounded-lg  dark:text-white  flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                              style={{
                                backgroundColor: `${appData.business.primary_color}10`,
                              }}
                              focused={focused.toString()}
                              required
                              autoComplete="off"
                              name="value_reciever_number"
                              value={user.data.phone_number}
                              inputMode="numeric"
                              pattern="^[0-9]{11,11}$"
                              onBlur={handleFocus}
                              disabled
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className=" relative ">
                <label>
                  <p className="mt-5 font-medium text-gray-700 dark:text-gray-200   text-sm md:text-base">
                    OTP
                  </p>
                  <div className="flex relative mt-1">
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className=" rounded-l-lg flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   "
                      placeholder="00000"
                      value={formData.otp}
                      required
                      inputMode="numeric"
                      focused={focused.toString()}
                      pattern="^[0-9]+$"
                      onBlur={handleFocus}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                    />
                    <span className="rounded-r-md inline-flex bg-primary-orange items-center px-3 border-t text-white border-r border-b  border-slate-300 shadow-sm text-sm md:text-base">
                      <button className="font-semibold" onClick={getOTP}>
                        {verifyingMeter ? (
                          <div className="flex items-center justify-center ">
                            <img
                              src={loadingSmall}
                              alt="loading ..."
                              className="w-7 h-7 "
                            />
                          </div>
                        ) : formData.otpRetrieved ? (
                          `OTP Sent!`
                        ) : (
                          `Get OTP`
                        )}
                      </button>
                    </span>
                  </div>
                </label>
              </div>
            </div>
            <span className="block w-full rounded-md shadow-sm">
              <button
                className="py-2 px-4 md:py-4 md:px-8 bg-primary-orange hover:bg-primary-orange focus:ring-primary-orange focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base  md:text-lg font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg mt-2"
                type="submit"
                id="payButton"
                onClick={(e) => {
                  if (sending) {
                    e.preventDefault();
                    return;
                  }
                }}
              >
                {sending ? (
                  <div className="flex items-center justify-center">
                    <img
                      src={loadingSmall}
                      alt="loading ..."
                      className="w-7 h-7 "
                    />
                  </div>
                ) : (
                  "Verify Phone Number"
                )}
              </button>
            </span>
          </div>
        </div>
      </form>
    </Layout>
  );
}

export default VerifyPhone;
