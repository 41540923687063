import React, { useContext, useEffect } from "react";

import { FormContext } from "../providers/formValues";

export default function NumberCheckModal(props) {
  const { formData, formDispatch } = useContext(FormContext);
  const clearModal = () => {
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "NumberCheckModal",
        value: {
          isOpen: false,
          transactionType: "",
          transactionDescription: "",
          transactionReceiver: "",
          transactionAmount: "",
          transactionNetwork: "",
        },
      },
    });
  };

  useEffect(() => {
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "NumberCheckModal",
        value: {
          isOpen: false,
          type: "",
          description: "",
          receiver: "",
          amount: "",
          network: "",
        },
      },
    });
  }, []);

  return (
    <>
      {formData.NumberCheckModal.isOpen && (
        <div className="">
          <div
            className={
              "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-full  "
            }
          >
            <div className={"relative w-full my-6 mx-auto max-w-sm  px-4"}>
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={() => clearModal()}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center ">
                  <div className="">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <h3 className="mb-5 text-sm font-normal text-red-500 dark:text-gray-400">
                    Confirm Phone Number! It doesn't look like{" "}
                    <b> {formData.network}</b>
                  </h3>
                  <p className="text-center">
                    <b>Proceed with {formData.value_reciever_number}?</b>
                  </p>
                  <button
                    onClick={() => {
                      props.onConfirmationClicked();
                      clearModal();
                    }}
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => clearModal()}
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}
    </>
  );
}
