import aedc from "../icons/aedc.png";
import ekedc from "../icons/ekedc.png";
import ibedc from "../icons/ibedc.png";
import jedc from "../icons/jedc.png";
import kedc from "../icons/kedc.png";
import kedo from "../icons/kedo.png";
import phdc from "../icons/phdc.png";
import eedc from "../icons/eedc.png";
import bedc from "../icons/bedc.png";
import ikajeElectic from "../icons/ikedc.png";
import loadingSmall from "../icons/loadingSmall.svg";
import eye from "../icons/eye.svg";
import eyeclosed from "../icons/eye-blocked.svg";
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "../providers/auth";
import { FormContext } from "../providers/formValues";
import { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../providers/appData";
import ElecricityPlansModal from "../components/electrcityPlansModal";
import { UserContext } from "../providers/userData";
import PaymentType from "../components/paymentType";
import ConfirmationModel from "../components/confirmationModel";
import { getOS } from "../helper/getOs";
import CurrencyFormat from "../helper/CurrencyFormat";
import Layout from "../Layout";

function ElectricityPayments() {
  const { setShowModal, showModal } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [focused, setFocused] = useState(false);
  const [sending, setSending] = useState(false);
  const [verifyingMeter, setVerifyingMeter] = useState(false);
  const [Erro, setError] = useState({
    networkErro: "",
    planerro: "",
    receiverErro: "",
  });

  document.title = "Purchase Electric-" + appData.business.name;

  const handleFocus = (e) => {
    setFocused(true);
  };

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
    calculatePrice();
  };
  const onPlanSelect = (network) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "network", value: network },
    });
    calculatePrice();
  };

  function calculatePrice() {
    if (formData.amount && formData.network) {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "atmPrice",
          value:
            formData.amount -
            (formData.commission * formData.amount) / 100 +
            (formData.amount * 1.4) / 100,
        },
      });
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "walletPrice",
          value:
            formData.amount - (formData.amount * formData.commission) / 100,
        },
      });
    }
  }

  const history = useHistory();
  const verifyReceiver = (e) => {
    e.preventDefault();
    setVerifyingMeter(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("meter_number", String(formData.value_reciever_number));
    urlencoded.append("plan_id", String(formData.electricPlan_id));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "verify_meter", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);

        setVerifyingMeter(false);

        if (data.status === "success") {
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "receiverName", value: data.data.Customer_Name },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "receiverAddress", value: data.data.Address },
          });
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setSending(false); //
      });
  };
  const onValidSubmit = (e) => {
    e.preventDefault();
    if (!formData.network) {
      return setError({ networkErro: "Please pick a Distributor!" });
      // } else if (!formData.plans_name) {
      //   return setError({ Planerro: "Please pick a meter type!" });
    }
    // else if (!formData.receiverName) {
    //   return setError({
    //     receiverErro: "Enter valid Meter Number!",
    //   });
    // }
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "ConfirmationModal",
        value: {
          isOpen: true,
          type: "Electric Bill Payment",
          description: formData.plans_name,
          receiver: formData.receiverName || formData.value_reciever_number,
          amount: formData.amount
            ? formData.atmPayment
              ? formData.calcAmount
              : formData.amount - (formData.amount * formData.commission) / 100
            : 0,
          network: formData.network,
        },
      },
    });
  };
  const handleSubmit = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    setSending(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    user.data &&
      !formData.atmPayment &&
      urlencoded.append("password", String(formData.password));
    !user.data &&
      formData.atmPayment &&
      urlencoded.append("email", String(formData.email));
    urlencoded.append("plan_id", String(formData.electricPlan_id));
    urlencoded.append(
      "meter_number",
      String(formData.value_reciever_number || user.data.phone_number)
    );
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append(
      "phone_number",
      String(
        formData.phone_number ? formData.phone_number : user.data.phone_number
      )
    );
    urlencoded.append("payment_method", formData.atmPayment ? "ATM" : "WALLET");
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "electric_purchase", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success" && formData.atmPayment) {
          window.location = data.data.payment_url;
          return;
        }

        if (data.status === "success") {
          if (formData.atmPayment) window.location = data.data.payment_url;
          else
            formDispatch({
              type: "INPUTVALUES",
              data: {
                name: "Alert",
                value: { isOpen: true, message: data.message, show: true },
              },
            });
          setSending(false);
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
          setSending(false);
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
          setSending(false);
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setSending(false);
      });
  };
  useEffect(() => {
    if (formData.fromBeneficiaries) {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "fromBeneficiaries",
          value: false,
        },
      });
      setShowModal((preShowModel) => !preShowModel);
      return;
    }
  }, []);
  useEffect(() => {
    document
      .getElementById("electricity_number")
      .addEventListener("input", function () {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "value_reciever_number",
            value: this.value.replace(/\D/g, ""),
          },
        });
      });
    document
      .getElementById("phone_number")
      .addEventListener("input", function () {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "phone_number",
            value: this.value.replace(/\D/g, ""),
          },
        });
      });
  }, []);
  return (
    <Layout PageName={"Electricity Payments"}>
      <div className="">
        <h2 className="font-medium text-sm mt-4 md:text-lg">
          Select Distributor
        </h2>
        {formData.ConfirmationModal ? (
          <div>
            <ConfirmationModel onConfirmationClicked={handleSubmit} />
          </div>
        ) : (
          ""
        )}
        <div className="grid grid-cols-5 gap-4 mt-2 ">
          <button
            className="flex  flex-col justify-center items-center "
            onClick={() => {
              onPlanSelect("IKEDC");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "IKEDC"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={ikajeElectic}
                alt="ikajeElectic"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              IKEDC
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("KAEDCO");
              setError({ networkErro: "" });
            }}
          >
            {" "}
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "KAEDCO"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={kedo}
                alt="KAEDCO"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              KAEDCO
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("AEDC");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "AEDC"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={aedc}
                alt="aedc"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              AEDC
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("PHED");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "PHED"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={phdc}
                alt="PHED"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              PHED
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("JED");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "JED"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={jedc}
                alt="JED"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">JED</div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("IBEDC");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "IBEDC"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={ibedc}
                alt="IBEDC"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              IBEDC
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("EKEDC");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "EKEDC"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={ekedc}
                alt="ekedc"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              EKEDC
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("KEDCO");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "KEDCO"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={kedc}
                alt="KEDCO"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              KEDCO
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("EEDC");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "EEDC"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={eedc}
                alt="EEDC"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              EEDC
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("BEDC");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => {
                setShowModal(!showModal);
              }}
              className={
                formData.network === "BEDC"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={bedc}
                alt="BEDC"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              BEDC
            </div>
          </button>
        </div>
        <p className="text-red-500">{Erro.networkErro}</p>
      </div>
      <div className="">
        <div className="mt-5">
          <form className="w-full space-y-6" onSubmit={onValidSubmit}>
            <div>
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between">
                      <div className="font-medium text-gray-700 dark:text-gray-200   text-sm md:text-base">
                        Meter Type
                      </div>
                      <div className="font-medium  text-sm md:text-base">
                        {user.data &&
                          `Balance: ₦ ${CurrencyFormat(
                            user.data.wallet_balance
                          )}`}
                      </div>
                    </div>

                    <input
                      type="text"
                      autoComplete="off"
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                      placeholder="Select meter type"
                      value={formData.plans_name}
                      readOnly
                      name="select_plan"
                      required
                      disabled={!formData.network}
                      onClick={() => {
                        setShowModal(!showModal);
                        setError({ planerro: "" });
                      }}
                    ></input>
                    {showModal ? <ElecricityPlansModal /> : null}

                    <p className="text-red-500">{Erro.planerro}</p>
                  </label>
                </div>
              </div>
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <p className="mt-5 font-medium text-gray-700 dark:text-gray-200   text-sm md:text-base">
                      Meter Number
                    </p>
                    <div className="flex relative mt-1">
                      <input
                        type="text"
                        id="electricity_number"
                        name="value_reciever_number"
                        className=" rounded-l-lg flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   "
                        placeholder="0000 0000 0000"
                        value={formData.value_reciever_number}
                        required
                        inputMode="numeric"
                        focused={focused.toString()}
                        pattern="^[0-9]+$"
                        onBlur={handleFocus}
                        onChange={(e) => {
                          formOnChange(e);
                          setError({ receiverErro: "" });
                        }}
                      />
                      <span className="rounded-r-md inline-flex bg-primary-orange items-center px-3 border-t text-white border-r border-b  border-slate-300 shadow-sm text-sm md:text-base">
                        <button className="" onClick={verifyReceiver}>
                          {verifyingMeter ? (
                            <div className="flex items-center justify-center">
                              <img
                                src={loadingSmall}
                                alt="loading ..."
                                className="w-7 h-7 "
                              />
                            </div>
                          ) : formData.receiverName ? (
                            `Verified`
                          ) : (
                            `Verify`
                          )}
                        </button>
                      </span>
                    </div>
                    <p className="text-xs text-right">
                      {formData.receiverName}
                    </p>
                    <p className="text-red-500">{Erro.receiverErro}</p>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <p className="mt-5 font-medium text-gray-700 dark:text-gray-200   text-sm md:text-base">
                      Phone Number
                    </p>
                    <input
                      type="text"
                      id="phone_number"
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange   focus:   mt-1"
                      placeholder={user.data && user.data.phone_number}
                      required={user.data ? false : true}
                      name="phone_number"
                      value={formData.phone_number}
                      inputMode="numeric"
                      pattern="^[0-9]{11,11}$"
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                    />
                  </label>
                </div>
              </div>
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <p className="mt-4 font-medium text-gray-700 dark:text-gray-200   text-sm">
                      <div className="w-full">
                        <div className=" relative ">
                          <label>
                            <p className="mt-5 font-medium text-gray-700 dark:text-gray-200   text-sm md:text-base">
                              Amount to Recharge
                            </p>
                            <input
                              type="number"
                              className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange   focus:   mt-1 mb-3"
                              placeholder="Min ₦50, Max ₦100,000"
                              name="amount"
                              required
                              value={formData.amount}
                              inputMode="numeric"
                              focused={focused.toString()}
                              pattern="^[0-9]{2,6}$"
                              onBlur={handleFocus}
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                            <span>Please enter only valid amount</span>
                          </label>
                        </div>
                      </div>
                    </p>
                  </label>
                </div>
              </div>
              <PaymentType
                sending={sending}
                setFocused={setFocused}
                focused={focused}
                handleFocus={handleFocus}
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default ElectricityPayments;
